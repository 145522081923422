.IndexImage{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: -1;
}

.IndexTitle{ 
    font-size: 4em;
    margin: 0;
}

.IndexTitleBox{
    position: absolute;
    color: white;
    text-align: left;
    top: 30vh;
    left: 50px;

}

.IndexSubtitle{
    font-size: 2em;
    margin: 0;
    font-weight: normal;
}

.IndexCard{
    width: 50%;
    margin-left: 25%;
    padding-top: 50px;
    text-align: center;
    position: absolute;
    height: 200px;
    top: 65vh;
}

.TopNav{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
}

.FixedNav{
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
}