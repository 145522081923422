.body {
    background-color: #eee;
}

.paper {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.avatar{
    margin: 6px;
    background-color: orange;
}

.form{
    width: 100%;
    margin-top: 6px;
}

.submit{
    margin: 12px;
}

.error{
    background-color: #d50000!important;
    margin-top: 12px !important;
}