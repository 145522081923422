.Content{
    height: 100%;
    width: 100%;
}

.FullViewHeight{
    height: 100vh;
}

.TopNav{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
}

.FixedNav{
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
} 
