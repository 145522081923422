.UploadWrapper{
    padding-top: 200px;
    margin: 0 25%;
    text-align: center;
    
}

.UploadTitle{
    text-align: center !important;
}

.SampleLink{
    color: #00b7eb;
    text-decoration: underline;
    cursor: pointer;
}

@media(max-width: 500px){
    .UploadWrapper{
        margin: 0;
    }
}

.DropZone{
    flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #aaa;
  border-style: dashed;
  background-color: #fafafa;
  color: #aaa;
  outline: none;
  transition: border .24s ease-in-out;
  margin-bottom: 20px;
  width: 100%;
  height: 200px;
}

.DropZone p, .DropZone div{
    vertical-align: center;
    height: 100%;
    width: 100%;
}

.DropZone p{
    margin: 0;
    padding-top: 85px;
    height: 115px;
    color: gray;
}