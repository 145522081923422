.PaperWrapper{
    padding: 20px;
    word-wrap: break-word;
}

.Content{
    max-width: 100%;
    word-wrap: break-word;
    list-style-type: none;
    padding: 30px;
}

.Heading{
   word-wrap: break-word;
}

.ContentInner{
    word-wrap: break-word;
    padding-top: 5px;
    padding-bottom: 5px;
}