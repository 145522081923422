.Transparent{
    background-color: rgba(255,255,255,0) !important;
    box-shadow: none !important; 
    color: white !important;
}

.Transparent button:hover {
    border-bottom: 1px solid white;
    border-radius: 0px;
}

.SideMenu {
    width: 300px;
}