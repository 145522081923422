.card {
    min-width: 275,
}


.bullet {
    display: inline-block;
    margin: 0 2px;
    transform: scale(0.8);
}

.title {
    font-size: 14;
}

.pos {
    margin-bottom: 12;
}