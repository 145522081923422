.PaperTitle{
    margin: 0;
    padding: 20px;
}

.PaperTitle h1{
    margin: 0;
}

.PaperItem{
    padding: 10px;
}


.PaperItemInline{
    display: inline;
}

.StatContainer{
    margin: 0px !important;
    padding: 0px !important;
}