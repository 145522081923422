.Paper{
    height: auto;
    width: 100%;
    min-width: 120px;
}

.root{
    padding: 30px 30px;
    flex-grow: 1;
    height: auto;
    min-height: 100%;
    background-color: #eee;
}

.Erosion{
    margin: 10px;
}